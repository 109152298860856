<app-page-loader *ngIf="loading" text="Loading..."></app-page-loader>
<ng-container *ngIf="account$ | async as account">
  <mat-sidenav-container autosize [hasBackdrop]="true">
    <mat-sidenav
      #sideNav
      mode="over"
      fixedInViewport="true"
      class="app-sidenav no-print"
      [autoFocus]="false"
      (click)="closeSideNav()"
    >
      <span class="app-title">
        <img
          src="/assets/logo-fpar.png"
          alt="Title X | Family Planning Annual Report"
        />
      </span>
      <div
        *ngIf="selectedGrantDetails$ | async as selectedGrant"
        class="selected-grant"
      >
        {{ selectedGrant | fullGrantDisplay }}
      </div>
      <app-app-menu [menu]="appMenu" [appTitle]="appTitle"></app-app-menu>
    </mat-sidenav>
    <mat-sidenav-content class="app-sidenav-content">
      <app-nav
        [title]="appTitle"
        [user]="{
          info: account,
          avatar: accountAvatar,
          grantsCount: userGrantsCount
        }"
        [version]="appVersion"
        [selectedGrant]="selectedGrantDetails$ | async"
        [mode]="viewportMode"
        (appMenuButtonClick)="handleAppMenuClick()"
      >
      </app-nav>
      <mat-drawer-container [hasBackdrop]="false" autosize>
        <mat-drawer
          [disableClose]="true"
          [mode]="isDesktop ? 'side' : 'over'"
          [autoFocus]="false"
          [opened]="
            (isDesktop || isTablet) && (activeMenu$ | async) !== 'settings'
          "
          [class.activated]="!isDesktop && appMenuOpened"
          [class.rail]="!isDesktop && !appMenuOpened"
          (mouseenter)="openAppMenu()"
          (mouseleave)="closeAppMenu()"
          (click)="setAppMenuUnlocked()"
          (touchend)="setAppMenuTouchEvent()"
        >
          <app-app-menu
            [menu]="appMenu"
            [appTitle]="appTitle"
            [railMode]="!isDesktop && !appMenuOpened"
          >
          </app-app-menu>
        </mat-drawer>
        <mat-drawer-content
          [class.opened]="isTablet"
          (click)="closeAppMenu(true)"
        >
          <main id="content" *ngIf="!loading" cdkScrollable>
            <router-outlet></router-outlet>
          </main>
        </mat-drawer-content>
      </mat-drawer-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
