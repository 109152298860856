<app-gov-site-link></app-gov-site-link>
<app-security-link></app-security-link>
<div class="flex-column auth-container">
  <div
    class="sign-in mat-elevation-z2 animate__animated animate__faster"
    [class.animate__fadeIn]="securityNoticeShown"
  >
    <div class="flex-row-reverse flex-wrap content-container">
      <div class="flex-column content-signin-container">
        <div class="content-logo">
          <img
            src="/assets/logo-fpar.png"
            alt="Title X | Family Planning Annual Report"
          />
        </div>
        <div class="signin-status">
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="signInLoading$ | async"
          ></mat-progress-bar>
        </div>
        <form
          *ngIf="securityNoticeShown"
          class="content-signin"
          #form
          [formGroup]="signInForm"
          (ngSubmit)="signIn()"
          (submit)="(false)"
        >
          <fieldset class="flex-column">
            <mat-form-field>
              <mat-label>Username</mat-label>
              <input
                matInput
                name="username"
                formControlName="username"
                type="text"
                title="Username"
                aria-required="true"
              />
              <mat-error>
                <app-form-errors
                  [field]="signInForm.controls.username"
                ></app-form-errors>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input
                matInput
                name="password"
                formControlName="password"
                title="Password"
                [type]="showPasswordPlainText ? 'text' : 'password'"
                aria-required="true"
              />
              <mat-error>
                <app-form-errors
                  [field]="signInForm.controls.password"
                ></app-form-errors>
              </mat-error>
              <button
                type="button"
                mat-icon-button
                matSuffix
                (click)="showPasswordPlainText = !showPasswordPlainText"
                aria-label="Hide password"
                [attr.aria-pressed]="!showPasswordPlainText"
              >
                <mat-icon>{{
                  showPasswordPlainText ? 'visibility' : 'visibility_off'
                }}</mat-icon>
              </button>
            </mat-form-field>
          </fieldset>
          <div class="form-footer">
            <button
              type="submit"
              mat-flat-button
              color="primary"
              [disabled]="signInLoading$ | async"
            >
              Sign In
            </button>

            <a [routerLink]="['/auth/reset-password']">Forgot your password?</a>
          </div>
        </form>
        <mat-divider></mat-divider>
        <div class="flex-column flex-center content-tech-assistance">
          <div>
            <h3>Technical Assistance</h3>
            <p>
              For assistance using the FPAR 2.0 system, answers to specific
              questions about FPAR 2.0 requirements, or other technical
              assistance, please contact
              <a
                class="icon-link"
                href="mailto:FPARSupport@mathematica-mpr.com"
              >
                <mat-icon>email</mat-icon>
                <span>FPARSupport&#64;mathematica-mpr.com</span>
              </a>
            </p>
          </div>
          <div class="app-version">Version: {{ appVersion }}</div>
        </div>
      </div>
      <div class="flex-column content-description">
        <img src="assets/oash-logo.svg" alt="OPA" />
        <div class="flex-row flex-wrap">
          <div class="content-about-fpar">
            <h3>About FPAR</h3>
            <p>
              FPAR 2.0 is the new data collection and reporting system designed
              for Title X grantees and Office of Population Affairs staff. FPAR
              2.0 allows grantees to submit required Family Planning Annual
              Report (FPAR) service data. In addition, Grantees and Title X
              administrators can use FPAR 2.0 to analyze and report on FPAR
              data.
            </p>
          </div>
        </div>
        <div class="content-about-titlex">
          <h3>About Title X</h3>
          <p>
            The HHS Office of Population Affairs (OPA) funds Title X family
            planning service grantees who support hundreds of subrecipients and
            thousands of service sites. Family planning includes a broad range
            of services related to achieving pregnancy, preventing pregnancy,
            and assisting women, men, and couples with achieving their desired
            number and spacing of children.
          </p>
        </div>
        <div class="content-resources">
          <h3>Resources</h3>
          <p>
            <a
              href="https://opa.hhs.gov/research-evaluation/title-x-services-research/family-planning-annual-report/fpar2"
              target="new"
            >
              FPAR 2.0 Resources</a
            >
            are available to help grantees prepare and submit FPAR data. For
            further information regarding FPAR and a listing of past FPARs,
            please see
            <a
              href="https://opa.hhs.gov/research-evaluation/title-x-services-research/family-planning-annual-report-fpar/fpar-archive"
              target="new"
            >
              Family Planning Annual Reports</a
            >. <br /><br />
            Quick links to commonly used resources can be found below:
          </p>
          <ul>
            <li>
              <a
                class="icon-link"
                href="https://opa.hhs.gov/research-evaluation/title-x-services-research/family-planning-annual-report/fpar2#samples"
                ><mat-icon>open_in_new</mat-icon
                ><span
                  >Valid Values, Sample Files, and Additional Reporting
                  Guidance</span
                ></a
              >
            </li>
            <li>
              <a
                class="icon-link"
                href="https://opa.hhs.gov/research-evaluation/title-x-services-research/family-planning-annual-report/fpar2#guide"
                ><mat-icon>open_in_new</mat-icon
                ><span>Implementation Guide</span></a
              >
            </li>
            <li>
              <a
                class="icon-link"
                href="https://opa.hhs.gov/research-evaluation/title-x-services-research/family-planning-annual-report/faq"
                ><mat-icon>open_in_new</mat-icon><span>FAQs</span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-auth-footer></app-auth-footer>
