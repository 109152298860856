<div class="app-menu" [class.rail]="railMode">
  <div class="app-menu-container">
    <ng-container *ngFor="let list of menu; let i = index">
      <ng-container *appSecurableResource="list.resourceAccess">
        <div class="app-menu-header" *ngIf="list.title.length">
          <h2 class="mat-title">{{ list.title }}</h2>
        </div>
        <div class="app-menu-list">
          <ng-container *ngFor="let item of list.items">
            <a
              matRipple
              matRippleColor="#FFFFFF4A"
              class="app-menu-button"
              [class.active]="(activeMenu$ | async) === item.id"
              [attr.aria-label]="item.label"
              [routerLink]="[item.route]"
              *appSecurableResource="item.resourceAccess"
            >
              <mat-icon>{{ item.icon }}</mat-icon>
              <span
                *ngIf="!railMode"
                class="animate__animated animate__faster animate__fadeIn"
                >{{ item.label }}</span
              >
            </a>
          </ng-container>
        </div>
        <div class="menu-divider" *ngIf="i < menu.length - 1"></div>
      </ng-container>
    </ng-container>
  </div>
  <div class="app-menu-footer">
    <a
      href="#security"
      class="security-privacy"
      (click)="openSecurityPrivacy($event)"
    >
      <mat-icon class="material-icons-outlined">privacy_tip</mat-icon>
      <span *ngIf="!railMode">Security & Privacy</span>
    </a>
  </div>
</div>
