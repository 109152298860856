import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AuthCardComponent } from './auth-card/auth-card.component';
import { AuthRoutingModule } from './auth.routing';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthFooterComponent } from './footer/footer.component';
import { GovSiteLinkComponent } from './gov-site-link/gov-site-link.component';
import { NewUserComponent } from './new-user/new-user.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SecurityLinkComponent } from './security-link/security-link.component';
import { SelectUserGrantComponent } from './select-user-grant/select-user-grant.component';
import { SetMfaComponent } from './set-mfa/set-mfa.component';
import { SignInComponent } from './signin/signin.component';
import { SignOutComponent } from './signout/signout.component';
import { VerifyComponent } from './verify/verify.component';

const COMPONENTS = [
  AuthCardComponent,
  ChangePasswordComponent,
  GovSiteLinkComponent,
  NewUserComponent,
  ResetPasswordComponent,
  SelectUserGrantComponent,
  SetMfaComponent,
  SignInComponent,
  SignOutComponent,
  VerifyComponent,
];

const MODULES = [
  CommonModule,
  SharedModule,
  AuthRoutingModule,
  AuthFooterComponent,
  SecurityLinkComponent,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [...MODULES],
  exports: [...COMPONENTS],
  providers: [],
})
export class AuthModule {}
