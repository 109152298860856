import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AppStoreState, NotificationActions } from '@app/store';
import { INotification } from '@core/models';
import { Store } from '@ngrx/store';
import { NotificationTypes } from '@shared/enums';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toast',
  templateUrl: 'toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnChanges, OnDestroy, OnInit {
  @Input() toasts: INotification[] = [];
  @Input() autoCloseDelay = 0;

  public closedId$ = new Subject<number>();

  private closingId$ = new Subject<number>();
  private destroyed$ = new Subject<boolean>();
  private iconList = {
    [NotificationTypes.DANGER]: 'error',
    [NotificationTypes.INFO]: 'info',
    [NotificationTypes.SUCCESS]: 'check_circle',
    [NotificationTypes.WARN]: 'warning',
  };
  private toastCount = 0;

  constructor(private store$: Store<AppStoreState.State>) {}

  ngOnChanges() {
    if (this.toasts.length > this.toastCount) {
      const lastToast = this.toasts[this.toasts.length - 1];
      if (lastToast.type !== NotificationTypes.DANGER) {
        this.closingId$.next(lastToast.id);
      }
    }

    this.toastCount = this.toasts.length;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.closingId$
      .pipe(delay(this.autoCloseDelay * 1000), takeUntil(this.destroyed$))
      .subscribe((closingId) => {
        this.closedId$.next(closingId);
        setTimeout(() => this.close(closingId), 500);
      });
  }

  public getIcon(type: NotificationTypes): string {
    return this.iconList[type ?? NotificationTypes.INFO];
  }

  public close(id: number): void {
    this.store$.dispatch(NotificationActions.remove({ id }));
  }
}
