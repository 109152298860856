import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { checkPasswordCriteria } from '@app/shared/utilities';
import {
  AppStoreState,
  AuthenticationActions,
  AuthenticationSelectors,
  NotificationActions,
} from '@app/store';
import { SignInResponse } from '@auth/auth.models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NotificationTypes } from '@shared/enums';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
@Component({
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})
export class NewUserComponent implements OnDestroy, OnInit {
  public setNewUserForm: UntypedFormGroup;
  public setNewUserLoading$ = new Observable<boolean>();

  private destroyed$ = new Subject<boolean>();
  private signIn$ = new Observable<SignInResponse>();

  constructor(
    private actions$: Actions,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private store$: Store<AppStoreState.State>,
  ) {
    this.signIn$ = this.store$.select(AuthenticationSelectors.selectSignIn);

    this.setNewUserLoading$ = this.store$.select(
      AuthenticationSelectors.selectSetNewUserLoading,
    );

    this.actions$
      .pipe(
        ofType(AuthenticationActions.setNewUserFailure),
        takeUntil(this.destroyed$),
        tap((error) => {
          console.error(error);
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: error.message,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AuthenticationActions.setNewUserSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.router.navigateByUrl('/auth/security');
        }),
      )
      .subscribe();

    this.signIn$
      .pipe(
        takeUntil(this.destroyed$),
        tap((e) => {
          if (!e) {
            this.goBack();
          }
        }),
      )
      .subscribe();
  }

  public get env() {
    return environment;
  }

  ngOnInit(): void {
    this.setNewUserForm = this.formBuilder.group({
      newPassword: ['', Validators.required],
      verifyPassword: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public goBack(): void {
    this.router.navigate([environment.appRoutes.signIn]);
  }

  public passwordsMatch(): boolean {
    const form = this.setNewUserForm.value;
    return (
      form?.newPassword.length > 0 && form?.newPassword === form?.verifyPassword
    );
  }

  public passwordIsEntered(): boolean {
    return this.setNewUserForm.get('newPassword').value?.length > 0;
  }

  public passwordIsValid(): boolean {
    const passwordCriteria = checkPasswordCriteria(
      this.setNewUserForm.get('newPassword').value,
    );

    return Object.keys(passwordCriteria).every(
      (check) => passwordCriteria[check] === true,
    );
  }

  public submitNewUserForm(): void {
    if (this.setNewUserForm.invalid) {
      return;
    }

    const payload = {
      newPassword: this.setNewUserForm.get('newPassword').value,
    };

    this.store$.dispatch(NotificationActions.reset());
    this.store$.dispatch(AuthenticationActions.setNewUser({ payload }));
  }
}
