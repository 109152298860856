<div class="flex-row flex-wrap footer-top">
  <div>
    <img src="assets/hhs-logo.png" alt="HHS" />
  </div>
  <div>
    <h2>HHS Headquarters</h2>
    <address>
      <strong>U.S. Department of Health &amp; Human Services</strong><br />
      200 Independence Avenue, S.W.<br />
      Washington, D.C. 20201<br />
      Toll Free Call Center: 1-877-696-6775
    </address>
  </div>
  <div></div>
</div>

<div class="flex-row flex-wrap link-list">
  <div class="flex-row flex-wrap">
    <ul>
      <li>
        <a target="new" href="https://www.hhs.gov/about/contact-us/index.html"
          >Contact HHS</a
        >
      </li>
      <li><a target="new" href="https://www.hhs.gov/answers/">HHS FAQs</a></li>
      <li>
        <a
          target="new"
          href="https://www.hhs.gov/civil-rights/for-individuals/nondiscrimination/index.html"
          >Nondiscrimination Notice</a
        >
      </li>
    </ul>
    <ul>
      <li>
        <a target="new" href="https://www.hhs.gov/accessibility.html"
          >Accessibility</a
        >
      </li>
      <li>
        <a target="new" href="https://www.hhs.gov/privacy.html"
          >Privacy Policy</a
        >
      </li>
    </ul>
  </div>
  <div class="flex-row flex-wrap">
    <ul>
      <li>
        <a target="new" href="https://www.hhs.gov/about/budget/index.html"
          >Budget/Performance</a
        >
      </li>
      <li><a target="new" href="https://oig.hhs.gov">Inspector General</a></li>
      <li>
        <a
          target="new"
          href="https://www.hhs.gov/about/agencies/asa/eeo/resources/no-fear-act/index.html"
          >EEO/No Fear Act</a
        >
      </li>
      <li>
        <a
          target="new"
          href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html"
          >Vulnerability Disclosure Policy</a
        >
      </li>
    </ul>
    <ul>
      <li><a target="new" href="https://www.hhs.gov/foia/">FOIA</a></li>
      <li>
        <a target="new" href="https://www.whitehouse.gov">The White House</a>
      </li>
      <li><a target="new" href="https://usa.gov">USA.gov</a></li>
    </ul>
  </div>
</div>
