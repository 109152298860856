<app-auth-card
  [progress]="resetPasswordLoading$ | async"
  *ngIf="codeSent || bypassEmailAddress"
>
  <span auth-card-header>Reset Password</span>
  <p>
    A verification code has been sent to your email address. Please enter your
    verification code in the space below along with your new password.
  </p>
  <form
    [formGroup]="resetPasswordForm"
    autocomplete="reset-password"
    (ngSubmit)="submitResetPasswordForm()"
  >
    <fieldset>
      <div class="flex-field short">
        <mat-form-field>
          <mat-label>Verification Code</mat-label>
          <input
            matInput
            name="resetCode"
            formControlName="resetCode"
            placeholder=""
            title="Reset Code"
            type="text"
            autocomplete="resetCode"
            aria-required="true"
          />
          <mat-error>
            <app-form-errors
              [field]="resetPasswordForm.controls.resetCode"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
      </div>
      <app-password-error
        [newPassword]="resetPasswordForm.controls.newPassword.value"
      ></app-password-error>
      <div class="flex-field">
        <mat-form-field>
          <mat-label>New Password</mat-label>
          <input
            matInput
            name="newPassword"
            formControlName="newPassword"
            placeholder=""
            type="password"
            autocomplete="new-password"
            aria-required="true"
          />
          <mat-error>
            <app-form-errors
              [field]="resetPasswordForm.controls.newPassword"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
        <span class="icon-password-match">
          <mat-icon
            class="match"
            [class.hidden]="!passwordIsValid()"
            matTooltip="Password Meets Criteria"
          >
            check_circle
          </mat-icon>
          <mat-icon
            class="no-match"
            [class.hidden]="!passwordIsEntered() || passwordIsValid()"
            matTooltipPosition="right"
          >
            remove_circle_outline
          </mat-icon>
        </span>
      </div>
      <div class="flex-field">
        <mat-form-field>
          <mat-label>Re-Enter New Password</mat-label>
          <input
            matInput
            name="verifyPassword"
            formControlName="verifyPassword"
            placeholder=""
            type="password"
            autocomplete="new-password"
            aria-required="true"
          />
          <mat-error>
            <app-form-errors
              [field]="resetPasswordForm.controls.verifyPassword"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
        <span class="icon-password-match">
          <mat-icon
            class="match"
            [class.hidden]="!passwordsMatch()"
            matTooltip="Passwords Match"
            matTooltipPosition="right"
          >
            check_circle
          </mat-icon>
        </span>
      </div>
    </fieldset>
  </form>
  <span auth-card-actions>
    <button
      type="button"
      mat-flat-button
      color="primary"
      (click)="submitResetPasswordForm()"
      [disabled]="
        (resetPasswordLoading$ | async) ||
        resetPasswordForm.invalid ||
        !passwordsMatch() ||
        !passwordIsValid()
      "
    >
      Reset Password
    </button>
  </span>
</app-auth-card>
<app-auth-card
  [progress]="sendPasswordResetCodeLoading$ | async"
  *ngIf="!codeSent && !bypassEmailAddress"
>
  <span auth-card-header>Reset Password</span>
  <p>
    Enter your email address below to receive a verification code for resetting
    your password.
  </p>
  <form
    [formGroup]="sendPasswordResetCodeForm"
    (ngSubmit)="submitSendPasswordResetCodeForm()"
  >
    <fieldset class="flex-column">
      <mat-form-field>
        <mat-label>Email Address</mat-label>
        <input
          matInput
          name="email"
          title="Email"
          formControlName="email"
          placeholder=""
          type="text"
          autocomplete="off"
          aria-required="true"
        />
        <mat-error>
          <app-form-errors
            [field]="sendPasswordResetCodeForm.controls.email"
          ></app-form-errors>
        </mat-error>
      </mat-form-field>
    </fieldset>
  </form>
  <ng-container auth-card-actions>
    <button
      type="button"
      mat-flat-button
      color="primary"
      (click)="submitSendPasswordResetCodeForm()"
      [disabled]="
        (sendPasswordResetCodeLoading$ | async) ||
        sendPasswordResetCodeForm.invalid
      "
    >
      Send
    </button>
    <button type="button" mat-stroked-button (click)="goBack()">Go Back</button>
  </ng-container>
</app-auth-card>
