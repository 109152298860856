<app-gov-site-link></app-gov-site-link>
<app-security-link></app-security-link>
<div class="flex-column auth-container">
  <mat-card
    class="mat-elevation-z2"
    *ngIf="!loading"
    [ngClass]="{ large: size === 'large', small: size === 'small' }"
  >
    <mat-card-header>
      <mat-card-title>
        <ng-content select="[auth-card-header]"></ng-content>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="mat-card-status">
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="progress"
        ></mat-progress-bar>
      </div>
      <ng-content></ng-content>
    </mat-card-content>
    <mat-card-actions>
      <ng-content select="[auth-card-actions]"></ng-content>
    </mat-card-actions>
  </mat-card>
</div>
<app-auth-footer></app-auth-footer>
