<app-page-loader
  *ngIf="grantsLoading$ | async"
  text="Loading..."
></app-page-loader>
<ng-container *ngIf="grants$ | async as grants">
  <app-auth-card
    size="large"
    *ngIf="grants.length > 1"
    [progress]="setGrantsLoading$ | async"
  >
    <span auth-card-header>Select a Grant</span>
    <div class="grant-content">
      <p>
        Your account has access to all grants listed below. Please select a
        grant to view or submit data:
      </p>
      <app-user-grant-list
        [grants]="grants"
        class="flex-column grant-list"
        (grantSelected)="
          setGrantSelection($event.grantId, $event.subrecipientId)
        "
      ></app-user-grant-list>
    </div>
    <ng-container auth-card-actions>
      <button mat-stroked-button color="primary" (click)="signOut()">
        Sign Out
      </button>
    </ng-container>
  </app-auth-card>
</ng-container>
