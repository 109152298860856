import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationTypes } from '@app/shared/enums';
import { GrantActions, GrantSelectors, NotificationActions } from '@app/store';
import { UserGrantItem } from '@core/models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment as env } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  templateUrl: './select-user-grant.component.html',
  styleUrls: ['./select-user-grant.component.scss'],
})
export class SelectUserGrantComponent implements OnInit, OnDestroy {
  public defaultGrant: UserGrantItem;
  public grants$: Observable<UserGrantItem[]>;
  public grantsLoading$: Observable<boolean>;
  public setGrantsLoading$: Observable<boolean>;

  private destroyed$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private router: Router,
    private store$: Store,
  ) {
    this.grants$ = this.store$.select(GrantSelectors.selectGrantList);
    this.grantsLoading$ = this.store$.select(
      GrantSelectors.selectGrantListLoading,
    );

    this.setGrantsLoading$ = this.store$.select(
      GrantSelectors.selectGrantDetailsLoading,
    );

    this.actions$.pipe(
      ofType(GrantActions.setSelectedGrantFailure),
      takeUntil(this.destroyed$),
      tap(({ message }) => {
        this.store$.dispatch(
          NotificationActions.add({
            notificationType: NotificationTypes.DANGER,
            notificationText: message,
          }),
        );
      }),
    );

    this.actions$
      .pipe(
        ofType(GrantActions.setSelectedGrantSuccess),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => this.router.navigateByUrl('/'));

    this.grants$
      .pipe(
        filter((grants) => !!grants),
        takeUntil(this.destroyed$),
      )
      .subscribe((grants) => {
        if (grants.length === 0) {
          this.setGrantSelection(null, null);
        }

        if (grants.length === 1) {
          this.setGrantSelection(grants[0].grantId, grants[0].subrecipientId);
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public ngOnInit(): void {
    this.store$.dispatch(GrantActions.getGrantListByUser({}));
  }

  public goBack(): void {
    this.router.navigate([env.appRoutes.signIn]);
  }

  public setGrantSelection(
    grantId: number | null,
    subrecipientId: number | null,
  ): void {
    this.store$.dispatch(
      GrantActions.setSelectedGrant({ grantId, subrecipientId }),
    );
  }

  public signOut(): void {
    this.router.navigate([env.appRoutes.signOut]);
  }
}
