import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  changePasswordValidator,
  NewPasswordMatchesOldErrorStateMatcher,
  passwordValidator,
} from '@app/shared/directives';
import { NotificationTypes } from '@app/shared/enums';
import { checkPasswordCriteria } from '@app/shared/utilities';
import {
  AppStoreState,
  AuthenticationActions,
  AuthenticationSelectors,
  NotificationActions,
} from '@app/store';
import { AuthService } from '@core/services';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnDestroy, OnInit {
  public bypassEmailAddress = false;
  public codeSent = false;
  public emailAddress = '';
  public errorStateMatcher = new NewPasswordMatchesOldErrorStateMatcher();
  public changePasswordForm: UntypedFormGroup;
  public changePasswordLoading$ = new Observable<boolean>();

  private destroyed$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private store$: Store<AppStoreState.State>,
  ) {
    this.changePasswordLoading$ = this.store$.select(
      AuthenticationSelectors.selectChangeExpiredPasswordLoading,
    );

    this.actions$
      .pipe(
        ofType(AuthenticationActions.changeExpiredPasswordFailure),
        takeUntil(this.destroyed$),
        tap((error) => {
          console.error(error);
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: error.message,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AuthenticationActions.changeExpiredPasswordSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.goBack();
          // TODO: Find out where sign out is clearing
          // everything so we don't need this.
          window.setTimeout(() => {
            this.store$.dispatch(
              NotificationActions.add({
                notificationType: NotificationTypes.SUCCESS,
                notificationText:
                  'Your password has been changed.  Please sign in with your username and new password.',
              }),
            );
          }, 300);
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group(
      {
        oldPassword: [
          '',
          {
            validators: [Validators.required, passwordValidator()],
          },
        ],
        newPassword: [
          '',
          {
            validators: [Validators.required, passwordValidator()],
          },
        ],
        verifyPassword: [
          '',
          {
            validators: [Validators.required, passwordValidator()],
          },
        ],
      },
      { validators: [changePasswordValidator()] },
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public goBack(): void {
    this.router.navigate([environment.appRoutes.signIn]);
  }

  public passwordIsEntered(): boolean {
    return this.changePasswordForm.get('newPassword').value?.length > 0;
  }

  public passwordIsValid(): boolean {
    const passwordCriteria = checkPasswordCriteria(
      this.changePasswordForm.get('newPassword').value,
    );

    return Object.keys(passwordCriteria).every(
      (check) => passwordCriteria[check] === true,
    );
  }

  public passwordsMatch(): boolean {
    const form = this.changePasswordForm.value;
    return (
      form?.newPassword.length > 0 && form?.newPassword === form?.verifyPassword
    );
  }

  public submitChangePasswordForm(): void {
    if (this.changePasswordForm.invalid) {
      return;
    }
    const payload = {
      ...this.changePasswordForm.value,
      accessToken: this.authService.getAccessToken(),
    };

    this.store$.dispatch(NotificationActions.reset());
    this.store$.dispatch(
      AuthenticationActions.changeExpiredPassword({ payload }),
    );
  }
}
