<app-auth-card [progress]="setNewUserLoading$ | async">
  <span auth-card-header>Complete Account Setup</span>
  <p>
    Welcome to the FPAR system. Your account is almost ready. Please enter your
    new password below.
  </p>
  <form
    [formGroup]="setNewUserForm"
    autocomplete="off"
    (ngSubmit)="submitNewUserForm()"
  >
    <fieldset *ngIf="false">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input
          matInput
          name="name"
          title="First Name"
          formControlName="name"
          placeholder=""
          autocomplete="fparFamilyName"
          aria-required="true"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input
          matInput
          name="familyName"
          title="Last Name"
          formControlName="familyName"
          placeholder=""
          autocomplete="fparFamilyName"
          aria-required="true"
        />
      </mat-form-field>
    </fieldset>
    <h3>New Password</h3>
    <fieldset class="flex-column">
      <p>Your new password must meet the following criteria:</p>
      <app-password-error
        class="password-error"
        [newPassword]="setNewUserForm.controls.newPassword.value"
      >
      </app-password-error>
      <div class="flex-field">
        <mat-form-field>
          <mat-label>New Password</mat-label>
          <input
            matInput
            name="newPassword"
            formControlName="newPassword"
            title="New Password"
            placeholder=""
            type="password"
            autocomplete="new-password"
            aria-required="true"
          />
          <mat-error>
            <app-form-errors
              [field]="setNewUserForm.controls.newPassword"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
        <span class="icon-password-match">
          <mat-icon
            class="match"
            [class.hidden]="!passwordIsValid()"
            matTooltip="Password Meets Criteria"
          >
            check_circle
          </mat-icon>
          <mat-icon
            class="no-match"
            [class.hidden]="!passwordIsEntered() || passwordIsValid()"
            matTooltipPosition="right"
          >
            remove_circle_outline
          </mat-icon>
        </span>
      </div>
      <div class="flex-field">
        <mat-form-field>
          <mat-label>Re-Enter New Password</mat-label>
          <input
            matInput
            name="verifyPassword"
            formControlName="verifyPassword"
            title="Verify Password"
            placeholder=""
            type="password"
            autocomplete="new-password"
            aria-required="true"
          />
          <mat-error>
            <app-form-errors
              [field]="setNewUserForm.controls.verifyPassword"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
        <span class="icon-password-match">
          <mat-icon
            class="match"
            [class.hidden]="!passwordsMatch()"
            matTooltip="Passwords Match"
            matTooltipPosition="right"
          >
            check_circle
          </mat-icon>
        </span>
      </div>
    </fieldset>
  </form>
  <ng-container auth-card-actions>
    <button
      type="button"
      mat-flat-button
      color="primary"
      (click)="submitNewUserForm()"
      [disabled]="
        (setNewUserLoading$ | async) ||
        !setNewUserForm.valid ||
        !passwordsMatch() ||
        !passwordIsValid()
      "
    >
      Continue
    </button>
  </ng-container>
</app-auth-card>
