<mat-toolbar role="navigation" [class.large]="isDesktop">
  <span class="toolbar-left">
    <a class="skip-content" href="" (click)="goToMainElement($event)"
      >Skip navigation</a
    >
    <button
      #appMenuButton
      aria-label="Application Menu"
      mat-icon-button
      (click)="appMenuButtonClicked($event)"
      *ngIf="!isDesktop"
      class="app-button"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <span class="flex-row app-title">
      <img
        src="/assets/logo-fpar.png"
        alt="Title X | Family Planning Annual Report"
      />
    </span>
    <div class="section-content" *ngIf="isTablet || isDesktop">
      <ng-container *ngIf="selectedGrant; else reviewTitle">
        <div class="title">
          {{ selectedGrant | fullGrantDisplay }}
        </div>
        <div class="subtitle">
          <mat-icon>person</mat-icon> <span>{{ selectedGrant.roleName }}</span>
        </div>
      </ng-container>
    </div>
    <ng-content select="[toolbar-left]"></ng-content>
  </span>
  <span class="toolbar-spacer">
    <ng-content></ng-content>
  </span>
  <span class="toolbar-right">
    <ng-content select="[toolbar-right]"></ng-content>

    <button
      mat-icon-button
      (click)="openUserGrantListPanel()"
      matTooltip="Grants List"
      *ngIf="user?.grantsCount > 1"
      aria-label="Grants List"
    >
      <mat-icon>format_list_bulleted</mat-icon>
    </button>
    <button
      mat-icon-button
      [routerLink]="['/help']"
      matTooltip="Help"
      *ngIf="isDesktop"
      aria-label="Help"
    >
      <mat-icon>help_outline</mat-icon>
    </button>
    <button
      mat-icon-button
      [routerLink]="['/settings']"
      matTooltip="Administration"
      *ngIf="user?.info?.isAdmin"
    >
      <mat-icon>admin_panel_settings</mat-icon>
    </button>
    <button
      mat-icon-button
      class="user-button"
      [matMenuTriggerFor]="userMenu"
      matTooltip="{{ user?.info?.nameFirst }} {{ user?.info?.nameLast }}"
    >
      <span
        class="user-avatar"
        *ngIf="user.avatar"
        [style.backgroundImage]="avatarURLStyle"
      ></span>
      <span class="user-avatar-initials" *ngIf="!user.avatar">{{
        avatarInitials
      }}</span>
      <mat-icon class="drop-down-arrow">arrow_drop_down</mat-icon>
    </button>
  </span>
</mat-toolbar>

<mat-menu
  #userMenu="matMenu"
  class="nav-menu user-menu"
  backdropClass="nav-menu-backdrop"
>
  <div
    class="user-menu"
    *ngIf="user?.info"
    (keydown.tab)="$event.stopPropagation()"
    (keydown.shift.tab)="$event.stopPropagation()"
  >
    <div class="user-info-container">
      <div class="user-avatar" [style.backgroundImage]="avatarURLStyle">
        <mat-icon>person</mat-icon>
      </div>
      <div class="user-info">
        <div class="user-name">
          {{ user?.info.nameFirst }} {{ user?.info.nameLast }}
        </div>
        <div class="user-email">
          {{ user?.info.email }}
        </div>
        <div class="user-role">
          {{ user?.info.roleName }}
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="user-actions" [cdkTrapFocus]="true">
      <span mat-mdc-menu-item mat-mdc-menu-item-container tabindex="-1">
        <button
          mat-stroked-button
          (click)="viewAccount()"
          cdkFocusInitial
          tabindex="1"
        >
          <mat-icon>manage_accounts</mat-icon>
          <span>My Account</span>
        </button>
      </span>
      <span mat-mdc-menu-item mat-mdc-menu-item-container tabindex="-1">
        <button mat-stroked-button (click)="signOut()" tabindex="2">
          <mat-icon>exit_to_app</mat-icon>
          <span>Sign Out</span>
        </button>
      </span>
    </div>
    <mat-divider></mat-divider>
    <div class="app-version">
      <div>&copy; Mathematica</div>
      <div>Version {{ version }}</div>
    </div>
  </div>
</mat-menu>

<ng-template #reviewTitle>
  <ng-container *ngIf="user?.info as account">
    <div class="title">Grant Submission Administration</div>
    <div class="subtitle" *ngIf="account.roleName">
      <mat-icon>person</mat-icon> <span>{{ account.roleName }}</span>
    </div>
  </ng-container>
</ng-template>
