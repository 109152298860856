import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { appMenuConfig } from '@app/app-menu.config';
import {
  AccountActions,
  AccountSelectors,
  AppStoreState,
  GrantActions,
  GrantSelectors,
  MenuSelectors,
  ReportingPeriodActions,
  ResourceAccessSelectors,
} from '@app/store';
import {
  Account,
  AppMenuList,
  ResourceAccessObject,
  UserGrantItem,
} from '@core/models';
import { AppService, DialogService } from '@core/services';
import { Dialog, DialogButton, DialogType } from '@core/ui/dialog';
import { Store } from '@ngrx/store';
import {
  Themes,
  ViewportHeight,
  ViewportMediaQuery,
  ViewportMode,
} from '@shared/enums';
import { IdleTimeoutDialogComponent } from '@shared/ui/dialogs';
import { environment as env } from 'environments/environment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { delay, filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild('sideNav') sideNav: MatSidenav;

  public account$: Observable<Account>;
  public accountAvatar = '';
  public activeMenu$: Observable<string>;
  public appMenu: AppMenuList[];
  public appMenuLocked = false;
  public appMenuOpened = false;
  public appMenuTouchEvent = false;
  public appTitle = '';
  public appVersion = '';
  public loading = true;
  public selectedGrantDetails$: Observable<UserGrantItem>;
  public userGrantsCount = 0;
  public viewportMode = ViewportMode.Default;

  private accountLoading$: Observable<boolean>;
  private destroyed$ = new Subject<boolean>();
  private grantList$: Observable<UserGrantItem[]>;
  private resourceAccess$: Observable<ResourceAccessObject>;
  private resourceAccessLoading$: Observable<boolean>;
  private selectedGrantDetailsLoading$: Observable<boolean>;
  private siteTheme = Themes.Light;
  private timeoutId: NodeJS.Timeout;
  private timeoutLength = env.timeoutLength * 60 * 1000;
  private viewportHeight = ViewportHeight.Default;
  private viewportIsDesktop = true;
  private viewportIsTablet = true;
  private worker: Worker;

  constructor(
    private app: AppService,
    private breakpointObserver: BreakpointObserver,
    private dialogService: DialogService,
    private renderer: Renderer2,
    private router: Router,
    private store$: Store<AppStoreState.State>,
  ) {
    this.account$ = this.store$.select(AccountSelectors.selectAccount);
    this.accountLoading$ = this.store$.select(
      AccountSelectors.selectUserDetailsLoading,
    );
    this.activeMenu$ = this.store$.select(MenuSelectors.selectActiveMenu);

    this.grantList$ = this.store$.select(GrantSelectors.selectGrantList);
    this.resourceAccess$ = this.store$.select(
      ResourceAccessSelectors.selectResourceAccess,
    );
    this.resourceAccessLoading$ = this.store$.select(
      ResourceAccessSelectors.selectResourceAccessLoading,
    );
    this.selectedGrantDetails$ = this.store$.select(
      GrantSelectors.selectGrantDetails,
    );
    this.selectedGrantDetailsLoading$ = this.store$.select(
      GrantSelectors.selectGrantDetailsLoading,
    );

    this.router.events
      .pipe(
        delay(2000),
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$),
        tap(() => {
          this.worker.postMessage({
            command: 'resetTimer',
            timeoutId: this.timeoutId,
            timeoutLength: this.timeoutLength,
          });
          this.closeAppMenu(true);
        }),
      )
      .subscribe();

    this.grantList$
      .pipe(
        filter((res) => !!res),
        takeUntil(this.destroyed$),
        tap((grantList) => {
          this.userGrantsCount = grantList.length;
        }),
      )
      .subscribe();

    combineLatest([
      this.accountLoading$,
      this.resourceAccessLoading$,
      this.selectedGrantDetailsLoading$,
    ])
      .pipe(
        map((loading) => loading.some((l) => l)),
        takeUntil(this.destroyed$),
      )
      .subscribe((loading) => (this.loading = loading));

    this.setSiteTheme(this.siteTheme);

    if (typeof Worker !== 'undefined') {
      // Create a new worker
      this.worker = new Worker(new URL('./idle-timer.worker', import.meta.url));
      this.worker.onmessage = ({ data }) => {
        const { message, command, timeoutId } = data;
        switch (command) {
          case 'timerStarted':
            this.timeoutId = timeoutId;
            break;
          case 'endTimer':
            this.timeOutPrompt();
            break;
          case 'timerReset':
            this.timeoutId = timeoutId;
            break;
          default:
            console.error('[idle-timer] No matching command');
        }
      };
      this.worker.postMessage({
        command: 'startTimer',
        timeoutId: this.timeoutId,
        timeoutLength: this.timeoutLength,
      });
    } else {
      console.error('Web workers are not supported in this environment');
    }
  }

  public get isDesktop(): boolean {
    return this.viewportMode >= ViewportMode.Desktop;
  }

  public get isMobile(): boolean {
    return this.viewportMode === ViewportMode.Mobile;
  }

  public get isShortScreen(): boolean {
    return this.viewportHeight === ViewportHeight.Short;
  }

  public get isTablet(): boolean {
    return this.viewportMode === ViewportMode.Tablet;
  }

  ngOnInit(): void {
    this.handleBreakpoints();

    this.resourceAccess$
      .pipe(
        filter((resource) => !!resource),
        takeUntil(this.destroyed$),
      )
      .subscribe((resources) => {
        this.appMenu = appMenuConfig;
        this.appTitle = this.app.appTitle;
        this.appVersion = env.version;

        this.store$.dispatch(AccountActions.getUserData());
        this.store$.dispatch(GrantActions.getGrantListByUser({}));
        this.store$.dispatch(ReportingPeriodActions.getReportingPeriodState());
      });
  }

  ngOnDestroy(): void {
    this.worker.terminate();
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.renderer.removeClass(document.body, this.siteTheme);
  }

  public closeAppMenu(forced: boolean = false): void {
    if (!this.appMenuLocked || (forced && this.appMenuLocked)) {
      this.appMenuOpened = false;
      this.appMenuLocked = false;
    }
  }

  public closeSideNav(): void {
    this.sideNav.close();
  }

  public handleAppMenuClick(): void {
    if (!this.isTablet && !this.isDesktop) {
      this.sideNav.toggle();
    } else {
      this.toggleAppMenu();
    }
  }

  public openAppMenu(): void {
    if (!this.appMenuTouchEvent && !this.appMenuLocked) {
      this.appMenuOpened = true;
    }

    this.appMenuTouchEvent = false;
  }

  public setAppMenuUnlocked(): void {
    this.appMenuLocked = false;
  }

  public setAppMenuTouchEvent(): void {
    this.appMenuTouchEvent = true;
  }

  public timeOutPrompt(): void {
    const prompt = new Dialog({
      dialogButton: DialogButton.continueCancel,
      dialogTemplate: IdleTimeoutDialogComponent,
      dialogTitle: 'Idle Warning',
      dialogType: DialogType.Warning,
    });

    this.dialogService
      .open<IdleTimeoutDialogComponent>(prompt)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res) {
          // this.idleTimeout.resetTimer();
          this.worker.postMessage({
            command: 'resetTimer',
            timeoutId: this.timeoutId,
            timeoutLength: this.timeoutLength,
          });
        } else {
          this.worker.terminate();
          this.router.navigate([env.appRoutes.signOut]);
        }
      });
  }

  public toggleAppMenu(): void {
    this.appMenuLocked = !this.appMenuOpened;
    this.appMenuOpened = !this.appMenuOpened;
  }

  private handleBreakpoints(): void {
    this.breakpointObserver
      .observe(ViewportMediaQuery.Mobile)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewportMode = ViewportMode.Mobile;
        }
      });

    this.breakpointObserver
      .observe(ViewportMediaQuery.Tablet)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: BreakpointState) => {
        this.viewportIsTablet = state.matches;
        if (state.matches) {
          this.viewportMode = ViewportMode.Tablet;
        }
      });

    this.breakpointObserver
      .observe(ViewportMediaQuery.Desktop)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: BreakpointState) => {
        this.viewportIsDesktop = state.matches;
        this.viewportMode =
          state.matches && !this.isShortScreen
            ? ViewportMode.Desktop
            : this.viewportMode;
      });

    this.breakpointObserver
      .observe(ViewportMediaQuery.Short)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: BreakpointState) => {
        const viewportHeight = this.viewportHeight;
        this.viewportHeight = state.matches
          ? ViewportHeight.Short
          : ViewportHeight.Normal;

        if (this.viewportIsDesktop) {
          this.viewportMode =
            this.viewportHeight === ViewportHeight.Short
              ? ViewportMode.Tablet
              : ViewportMode.Desktop;
        }
      });
  }

  private setSiteTheme(theme: Themes): void {
    this.siteTheme = theme;
    this.renderer.addClass(document.body, this.siteTheme);
  }
}
