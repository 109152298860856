<app-auth-card size="large">
  <span auth-card-header>Additional Security</span>
  <p>Please select your preference for additional security:</p>
  <form
    [formGroup]="setMFASelectionForm"
    autocomplete="off"
    (ngSubmit)="submitMFASelectionForm()"
  >
    <fieldset>
      <mat-radio-group class="radio-group" formControlName="mfaSelection">
        <mat-radio-button class="radio-button" [value]="MFATypes.SMS"
          >Text Message (SMS)
        </mat-radio-button>
        <mat-radio-button
          class="radio-button"
          [value]="MFATypes.Token"
          (change)="totpSelected($event)"
        >
          Google Authenticator
        </mat-radio-button>
      </mat-radio-group>
    </fieldset>
    <div class="mfa-container" *ngIf="mfaSelection() === MFATypes.SMS">
      <p>Please enter your mobile phone number:</p>
      <fieldset class="flex-inline">
        <mat-form-field class="country-code width-auto">
          <mat-label>Country Code</mat-label>
          <mat-select formControlName="countryCode">
            <mat-option value="+1">+1</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="phone-number">
          <mat-label>Mobile Phone Number</mat-label>
          <input
            type="text"
            matInput
            name="phoneNumber"
            title="Phone Number"
            formControlName="phoneNumber"
            maxlength="10"
          />
          <mat-error>
            <app-form-errors
              [field]="setMFASelectionForm.controls.phoneNumber"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
      </fieldset>
    </div>
    <div class="mfa-container" *ngIf="mfaSelection() === MFATypes.Token">
      <h3 class="steps-title">Google Authentication Steps</h3>
      <ol>
        <li>
          Download the Google Authenticator app on your phone from the App Store
          or Google Play.
          <div class="image-container">
            <a href="https://play.google.com/" target="new">
              <img
                class="logo"
                src="/assets/google-play-logo.png"
                alt="Google Play Store"
              />
            </a>
            <a href="https://www.apple.com/app-store/" target="new">
              <img
                class="logo"
                src="/assets/app-store-logo.png"
                alt="App Store"
              />
            </a>
          </div>
        </li>
        <li>
          If this is the first time you are using the Authenticator app then
          click "Get Started", otherwise, click "Scan a QR code". Allow
          Authenticator to access your camera (if prompted)
        </li>
        <li>
          Scan this QR code
          <div>
            <app-qr-code [qrcode]="qrcode"></app-qr-code>
          </div>
        </li>
        <li>
          Enter the verification code returned from the Authenticator app, then
          click the "Continue" button. Please note for security purposes the
          verification code changes every 30 seconds.
        </li>
      </ol>
      <fieldset class="auth-code">
        <mat-form-field>
          <mat-label>Verification Code</mat-label>
          <input
            matInput
            name="code"
            formControlName="code"
            placeholder=""
            type="text"
            aria-required="true"
          />
          <mat-error>
            <app-form-errors
              [field]="setMFASelectionForm.controls.code"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
      </fieldset>
    </div>
  </form>
  <ng-container auth-card-actions>
    <button
      mat-flat-button
      color="primary"
      type="button"
      (click)="submitMFASelectionForm()"
      [disabled]="!mfaSelected()"
    >
      Continue
    </button>
    <button mat-stroked-button type="button" (click)="goBack()">Go Back</button>
  </ng-container>
</app-auth-card>
